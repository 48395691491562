<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group ">
          <label for="emailInput">{{ $t('Email') }}</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" :placeholder="$t('Email')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">{{ $t('Password') }}</label>
          <input type="password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" :placeholder="$t('Password')" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">{{ $t("Login") }}</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import authService from '../../../services/auth'

  export default {
    name: 'SignInForm',
    props: ['formType', 'email', 'password'],
    data: () => ({
      user: {
        email: '',
        password: ''
      }
    }),
    mounted() {
      this.user.email = this.$props.email
      this.user.password = this.$props.password
    },
    methods: {
      onSubmit() {
        this.login()
      },
      login() {
        authService.login(this.user)
          .then((response) => {
            if (!response.user) {
              this.$toastr.error(this.$t("LoginFailed"));
            } else {

              if (response.user.language && response.user.language.length > 0) {
                this.$bus.$emit('UiLanguageChanged', response.user.language);
              }

              this.$router.push({ name: 'home.dashboard' })
            }
          });
      }
    }
  }
</script>
